const autoScroll = (scrollDiv, item, btn) => setInterval(function () {
  let pos = scrollDiv.scrollTop();
  if (
    scrollDiv.scrollTop() + $(item).innerHeight() >=
    scrollDiv[0].scrollHeight
  ) {
    // console.log('test');
  } else {
    if (
      !$(scrollDiv).is(":hover") &&
      !$(btn).hasClass("playing")
    ) {
      scrollDiv.scrollTop(pos + 1);
    }
  }
}, 40);


if ($(window).width() > 1040) {
  const audioScroll = $(".js-audioScroll");

  audioScroll.on("scroll", function (e) {
    var $this = $(this),
      $items = $(".audioScroll"),
      scrollPosition = $this.scrollTop();


    if (scrollPosition > ($this.data("scroll-position") || 0)) {
      // Scrolling down
      var threshold =
        $items.height() -
        $this.height() -
        $(".audioScroll__item:last-child").height() + 20;

      if (scrollPosition > threshold) {
        var $firstResult = $(".audioScroll__item").slice(0, 4);
        $items.append($firstResult);

        scrollPosition -= $firstResult.height() + 20;
        $this.scrollTop(scrollPosition);
      }
    } else {
      // Scrolling up

      var threshold = $(".audioScroll__item:first-child").height() + 20;

      if (scrollPosition < threshold) {
        var $lastResult = $(".audioScroll__item").slice(-4);
        $items.prepend($lastResult);
        scrollPosition += $lastResult.height() + 40;
        $this.scrollTop(scrollPosition);
      }
    }
    $this.data("scroll-position", scrollPosition);
  });

  // make scrolliing auto
  autoScroll(audioScroll, "audioScroll__item", "audioScroll__item--btn");
}

const btnsScroll = document.querySelectorAll(".audioScroll__item--btn");
const audios = document.querySelectorAll("audio");

btnsScroll.forEach((btn) =>
  btn.addEventListener("click", () => {
    audios.forEach((audio) => audio.pause());

    const item = btn.closest(".audioScroll__item");
    const curAudio = item.querySelector("audio");

    if (btn.classList.contains("playing")) {
      btn.classList.remove("playing");
      curAudio.pause();
    } else {
      document.querySelector(".playing")?.classList.remove("playing");
      btn.classList?.add("playing");
      curAudio.play();
    }
  })
);

$(".audio-top .btn-control").click(function () {
  // $('audio').each(function(){
  //   $(this).get(0).pause();
  // })

  var audioNew = $(this).parent(".item").find(".music");

  if ($(this).hasClass("playing")) {
    $(this).removeClass("playing");
    audioNew.get(0).pause();
  } else {
    $(".audio-top .btn-control").removeClass("playing");
    $(this).addClass("playing");
    audioNew[0].play();
  }
});

// hand with player



if ($(window).width() > 1040) {
  const videoScroll = $(".js-videoScroll");
  videoScroll.on("scroll", function (e) {
    let $this = $(this),
      $items = $(".videoScroll"),
      scrollPosition = $this.scrollTop();
    if (scrollPosition > ($this.data("scroll-position") || 0)) {
      // Scrolling down
      let threshold = $items.height() - $this.height() - $(".videoScroll__item").height() + 20;

      if (scrollPosition > threshold) {
        var $firstResultHeight = $(".videoScroll__item");
        var $firstResult = $(".videoScroll__item").slice(0, 3);
        $items.append($firstResult);
        scrollPosition -= $firstResultHeight.height() + 20;
        $this.scrollTop(scrollPosition);
      }
    } else {
      // Scrolling up

      let threshold = $(".videoScroll__item").height() + 20;

      if (scrollPosition < threshold) {
        let $lastResult = $(".videoScroll__item").slice(-3);
        $items.prepend($lastResult);

        scrollPosition += $lastResult.height() + 20;
        $this.scrollTop(scrollPosition);
      }
    }
    $this.data("scroll-position", scrollPosition);
  });

  autoScroll(videoScroll, "videoScroll__item", "videoScroll__item-control");

}

// audioplayer

!(function (e, t, i, a) {
  var n = "ontouchstart" in t,
    o = n ? "touchstart" : "mousedown",
    r = n ? "touchmove" : "mousemove",
    l = n ? "touchcancel" : "mouseup",
    d = function (e) {
      var t = Math.floor(e / 3600),
        i = Math.floor((e % 3600) / 60),
        a = Math.ceil((e % 3600) % 60);
      return (
        (0 == t
          ? ""
          : t > 0 && t.toString().length < 2
          ? "0" + t + ":"
          : t + ":") +
        (i.toString().length < 2 ? "0" + i : i) +
        ":" +
        (a.toString().length < 2 ? "0" + a : a)
      );
    },
    s = function (e) {
      var t = i.createElement("audio");
      return !(
        !t.canPlayType ||
        !t
          .canPlayType("audio/" + e.split(".").pop().toLowerCase() + ";")
          .replace(/no/, "")
      );
    };
  e.fn.audioPlayer = function (t) {
    t = e.extend(
      {
        classPrefix: "audioplayer",
        strPlay: "",
        strPause: "",
        strVolume: "Volume",
      },
      t
    );
    var i = {},
      a = {
        playPause: "playpause",
        playing: "playing",
        time: "time",
        timeCurrent: "time-current",
        timeDuration: "time-duration",
        bar: "bar",
        barLoaded: "bar-loaded",
        barPlayed: "bar-played",
        volume: "volume",
        volumeButton: "volume-button",
        volumeAdjust: "volume-adjust",
        noVolume: "novolume",
        mute: "mute",
        mini: "mini",
      };
    for (var u in a) i[u] = t.classPrefix + "-" + a[u];
    return (
      this.each(function () {
        if ("audio" != e(this).prop("tagName").toLowerCase()) return !1;
        var a = e(this),
          u = a.attr("src"),
          v =
            "" === (v = a.get(0).getAttribute("autoplay")) || "autoplay" === v,
          m = "" === (m = a.get(0).getAttribute("loop")) || "loop" === m,
          c = !1;
        void 0 === u
          ? a.find("source").each(function () {
              if (void 0 !== (u = e(this).attr("src")) && s(u))
                return (c = !0), !1;
            })
          : s(u) && (c = !0);
        var f = e(
            '<div class="' +
              t.classPrefix +
              '">' +
              (c
                ? e("<div>").append(a.eq(0).clone()).html()
                : '<embed src="' +
                  u +
                  '" width="0" height="0" volume="100" autostart="' +
                  v.toString() +
                  '" loop="' +
                  m.toString() +
                  '" />') +
              '<div class="' +
              i.playPause +
              '" title="' +
              t.strPlay +
              '"><a href="#">' +
              t.strPlay +
              "</a></div></div>"
          ),
          h = (h = c ? f.find("audio") : f.find("embed")).get(0);
        if (c) {
          f.find("audio").css({ width: 0, height: 0, visibility: "hidden" }),
            f.append(
              '<div class="' +
                i.time +
                " " +
                i.timeCurrent +
                '"></div><div class="' +
                i.bar +
                '"><div class="' +
                i.barLoaded +
                '"></div><div class="' +
                i.barPlayed +
                '"></div></div><div class="' +
                i.time +
                " " +
                i.timeDuration +
                '"></div><div class="' +
                i.volume +
                '"><div class="' +
                i.volumeButton +
                '" title="' +
                t.strVolume +
                '"><a href="#">' +
                t.strVolume +
                '</a></div><div class="' +
                i.volumeAdjust +
                '"><div><div></div></div></div></div>'
            );
          var p = f.find("." + i.bar),
            g = f.find("." + i.barPlayed),
            y = f.find("." + i.barLoaded),
            b = f.find("." + i.timeCurrent),
            w = f.find("." + i.timeDuration),
            P = f.find("." + i.volumeButton),
            C = f.find("." + i.volumeAdjust + " > div"),
            E = 0,
            $ = function (e) {
              (theRealEvent = n ? e.originalEvent.touches[0] : e),
                (h.currentTime = Math.round(
                  (h.duration * (theRealEvent.pageX - p.offset().left)) /
                    p.width()
                ));
            },
            S = function (e) {
              (theRealEvent = n ? e.originalEvent.touches[0] : e),
                (h.volume = Math.abs(
                  (theRealEvent.pageY - (C.offset().top + C.height())) /
                    C.height()
                ));
            },
            x = setInterval(function () {
              y.width((h.buffered.end(0) / h.duration) * 100 + "%"),
                h.buffered.end(0) >= h.duration && clearInterval(x);
            }, 100),
            L = h.volume,
            V = (h.volume = 0.111);
          Math.round(1e3 * h.volume) / 1e3 == V
            ? (h.volume = L)
            : f.addClass(i.noVolume),
            w.html("…"),
            b.text(d(0)),
            h.addEventListener("loadeddata", function () {
              w.text(d(h.duration)),
                C.find("div").height(100 * h.volume + "%"),
                (E = h.volume);
            }),
            h.addEventListener("timeupdate", function () {
              b.text(d(h.currentTime)),
                g.width((h.currentTime / h.duration) * 100 + "%");
            }),
            h.addEventListener("volumechange", function () {
              C.find("div").height(100 * h.volume + "%"),
                h.volume > 0 && f.hasClass(i.mute) && f.removeClass(i.mute),
                h.volume <= 0 && !f.hasClass(i.mute) && f.addClass(i.mute);
            }),
            h.addEventListener("ended", function () {
              f.removeClass(i.playing);
            }),
            p
              .on(o, function (e) {
                $(e),
                  p.on(r, function (e) {
                    $(e);
                  });
              })
              .on(l, function () {
                p.unbind(r);
              }),
            P.on("click", function () {
              return (
                f.hasClass(i.mute)
                  ? (f.removeClass(i.mute), (h.volume = E))
                  : (f.addClass(i.mute), (E = h.volume), (h.volume = 0)),
                !1
              );
            }),
            C.on(o, function (e) {
              S(e),
                C.on(r, function (e) {
                  S(e);
                });
            }).on(l, function () {
              C.unbind(r);
            });
        } else f.addClass(i.mini);
        v && f.addClass(i.playing);
        f.find("." + i.playPause).on("click", function () {
          return (
            f.hasClass(i.playing)
              ? (e(this).attr("title", t.strPlay).find("a").html(t.strPlay),
                f.removeClass(i.playing),
                c ? h.pause() : h.Stop())
              : (e(this).attr("title", t.strPause).find("a").html(t.strPause),
                f.addClass(i.playing),
                c ? h.play() : h.Play()),
            !1
          );
        }),
          a.replaceWith(f);
      }),
      this
    );
  };
})(jQuery, window, document);

$(function () {
  $(".music-default").audioPlayer();
  $(".audioplayer").css(
    "background-image",
    'url("https://twinkle.io/wp-content/uploads/2021/04/Cinematic.jpg")'
  );
});

// stop




$(".videoScroll__item--btn").click(function () {

  $("audio").each(function () {
    $(this).get(0).pause();
  });

  if ($(this).hasClass("playing")) {
    $(this).removeClass("playing");
    $(".audioplayer").removeClass("audioplayer-playing");
  } else {
    $(".playing").removeClass("playing");
    $(this).addClass("playing");

    const audioInner = $(".audioHand--inner");
    let imgSrc = $(this).parents(".videoScroll__item").find("img").attr("src");
    let musicName = $(this).parents(".videoScroll__item").find(".videoScroll__item--name").clone().removeClass('videoScroll__item--name"').addClass('audioHand--name');

    //code for the desktop to play in mobile
    $(".videoScroll__item.active").removeClass("active");

    $(this).parents(".videoScroll__item").addClass("active");

    audioInner.html("");
    $(this)
      .parents(".videoScroll__item")
      .find(".music")
      .clone()
      .appendTo(audioInner);

      $(".audioHand--inner").append(musicName);

      audioInner.find('.music').audioPlayer();
    $(".audioplayer").css("background-image", "url(" + imgSrc + ")");

    $(".audioHand--inner .music")[0].play();
    
    $(".audioplayer").addClass("audioplayer-playing");

  }
});
